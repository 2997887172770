//
// Environment editor
//
// --------------------------------------------------------

@import "../../styles/variables.scss";

$environment-editor-border-radius: $border-radius-large;
$environment-editor-box-shadow: $medium-box-shadow;

$header-background-colour: $colour-turquoise-200;
$header-text-colour: $colour-turquoise-700;
$selection-highlight-colour: $colour-copper-500;

.environment-editor-container-outer {
  z-index: $z-07-environment-editor;
  position: absolute;
  bottom: 90px;
  left: 50px;
}

.environment-editor-container-inner {
  background-color: white;
  border-radius: $environment-editor-border-radius;
  overflow: hidden;
  box-shadow: $environment-editor-box-shadow;
  font-size: 0.75rem;
  user-select: none;

  [dir="rtl"] & {
    left: auto;
    right: 50px;
  }
}

.environment-editor header {
  padding: 0.5em;
  background-color: $header-background-colour;
  color: $header-text-colour;
  display: flex;
  align-items: center;
}

.environment-editor.react-draggable header {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
}

.environment-editor.react-draggable-dragging header {
  cursor: grabbing;
}

.environment-editor header img {
  height: 16px;
  width: auto;
  margin-left: 0.5em;
  pointer-events: none;

  [dir="rtl"] & {
    margin-right: 0.5em;
    margin-left: auto;
  }
}

.environment-editor header h3 {
  flex: 1;
  min-width: 0;
  margin: 0 0.25em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.environment-editor-content {
  padding: 1em;
}

.environment-select-grid {
  display: grid;
  height: auto;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-auto-flow: row;
  grid-gap: 0.75em;
}

.environment-select {
  width: 32px;
  height: 32px;
  border-radius: $border-radius-large;
  cursor: pointer;
  transition: 120ms box-shadow;
}

.environment-select.environment-active {
  box-shadow: 0 0 0 2px $selection-highlight-colour,
    inset 0 0 0 2px rgba(255, 255, 255, 1);
}

/* Close button overrides */
.environment-editor .close {
  position: relative;
  width: 24px;
  height: 24px;
  padding: 0;
  top: auto;
  right: auto;
  display: flex !important;
  justify-content: center;
  align-items: center !important;
}
