button.scroll,
.scrollable {
  @include tap-highlight-color(transparent);

  position: absolute;
  top: 0;
  width: 25px;
  height: 40px;
  padding: 0;
  cursor: pointer;

  &[disabled] {
    opacity: 0;
    pointer-events: none;
  }
}

input[type="submit"],
button,
a.button-like {
  display: inline-block !important;
  outline: none;
  border: 0;
  border-radius: $border-radius-medium;
  padding: 0.75em 2em;
  background-color: $colour-turquoise-225;
  color: $colour-midnight-700;
  font-weight: bold;
  text-align: center;
  text-decoration: none;

  &:not([disabled]) .svg-inline--fa {
    color: $colour-midnight-700;
  }

  &:hover {
    background-color: $colour-turquoise-250;
  }

  &:active {
    background-color: $colour-turquoise-250;
  }

  &[disabled] {
    background-color: desaturate(fade-out($ui-colour, 0.7), 100%) !important;
    color: #999 !important;
    cursor: auto;

    .svg-inline--fa {
      color: #999 !important;
    }

    &:hover {
      background-color: desaturate(fade-out($ui-colour, 0.7), 100%) !important;
    }
  }

  &.button-primary {
    background-color: $colour-emerald-400;
    color: white;

    &:hover {
      background-color: mix($colour-emerald-400, $colour-emerald-500, 75%);
    }
  }

  &.button-secondary {
    background-color: $colour-turquoise-500;
    color: white;

    &:hover {
      background-color: mix($colour-turquoise-500, $colour-turquoise-600, 75%);
    }
  }

  &.button-tertiary {
    border: 1px solid $colour-turquoise-400;
    background-color: transparent;
    color: $colour-turquoise-600;

    &:hover {
      background-color: $colour-turquoise-100;
      color: $colour-turquoise-700;
    }
  }
}
