@import "../../styles/variables.scss";

.segment-drag-layer {
  position: fixed;
  pointer-events: none;
  z-index: 400; // TODO: Put on z-index hierarchy
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.segment.floating {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0.75;
  z-index: $z-02-segment-focused;
  pointer-events: none;
  transition: none !important;
}

.segment.floating.first-drag-move {
  transition: top 150ms;
}
