@import "../../styles/variables.scss";

.sentiment-survey-container {
  position: absolute;
  z-index: 190; // Underlay menubar and street meta area
  width: 100%;
  top: 0;
  bottom: 100px; // Overlap entirety of screen to prevent interaction until dismissed
  pointer-events: none;

  button.close {
    // Force the stock close button UI to appear above absolutely-positioned content
    z-index: 1;
  }
}

.sentiment-survey-background {
  opacity: 0;
  background: linear-gradient(rgba(23, 68, 70, 0.25) 30%, transparent 70%);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 200ms ease-in;
  pointer-events: none;

  .sentiment-survey-visible & {
    opacity: 1;
    pointer-events: auto;
  }
}

.sentiment-survey-dialog {
  background-color: white;
  border-radius: $border-radius-medium;
  margin: 0 auto;
  margin-top: 160px; // Clear street meta area
  margin-bottom: 15px;
  position: relative;
  width: max-content;
  max-width: 600px;
  padding: 1.5em 3em;
  box-shadow: $medium-box-shadow;
  text-align: center;
  pointer-events: auto;

  h2 {
    margin-top: 0.5em;
    margin-bottom: 0;
    color: $colour-turquoise-700;
    font-weight: normal;

    em {
      font-style: normal;
      font-weight: bold;
    }
  }

  p:first-of-type {
    margin-top: 0;
  }

  sub {
    display: block;
    margin-bottom: 1.5em;
    color: gray;
    font-style: italic;
  }
}

.sentiment-survey-about-link {
  margin-top: 1.5em;
  margin-bottom: 0;
}

.sentiment-survey-buttons {
  position: relative;

  button.sentiment-button {
    background: transparent;
    padding: 0 0.5em;
    transition: 300ms opacity;
    opacity: 1;
  }

  button.sentiment-button:not(:disabled):hover .sentiment-icon,
  button.sentiment-button:not(:disabled):focus .sentiment-icon {
    transform: scale(1.2);
  }

  /* Overwrite global button appearance when this button is disabled */
  button.sentiment-button:disabled {
    background-color: transparent !important;

    /* When disabled, fade out */
    &:not(.sentiment-selected) {
      opacity: 0;
    }
  }

  .sentiment-selected .sentiment-icon {
    animation-name: tada;
    animation-duration: 1000ms;
    animation-fill-mode: both;
  }
}

.sentiment-survey-sign-in-prompt {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* Modified version of `tada` from animate.css
   https://animate.style/ */
@keyframes tada {
  from {
    transform: scale3d(1.2, 1.2, 1.2);
  }

  10%,
  20% {
    transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, -3deg);
  }

  30%,
  50%,
  70%,
  90% {
    transform: scale3d(1.4, 1.4, 1.4) rotate3d(0, 0, 1, 3deg);
  }

  40%,
  60%,
  80% {
    transform: scale3d(1.4, 1.4, 1.4) rotate3d(0, 0, 1, -3deg);
  }

  to {
    transform: scale3d(1.2, 1.2, 1.2);
  }
}
