@import "../../styles/variables.scss";

$resize-guide-text-color: fade-out(black, 0.4);
$resize-guide-text-inverted-color: fade-out(white, 0.4);
$resize-guide-line: 1px dashed fade-out(black, 0.6);
$resize-guide-line-inverted: 1px dashed fade-out(white, 0.6);

.resize-guides {
  position: absolute;
  z-index: $z-07-resize-guide;
  top: -75px;
  bottom: 120px;
  left: 50%; /* Will be overridden by component */
  pointer-events: none;
}

.resize-guide {
  position: absolute;
  height: 100%;
  border-left: $resize-guide-line;
  border-right: $resize-guide-line;

  // Invert the UI text color when there is a dark background
  body.dark-environs-invert-ui & {
    border-left: $resize-guide-line-inverted;
    border-right: $resize-guide-line-inverted;
  }
}

.resize-guide-max-before,
.resize-guide-max-after,
.resize-guide-min-before,
.resize-guide-min-after {
  width: 50px;
  position: absolute;
  bottom: 400px;
  font-size: 0.8rem;
  color: $resize-guide-text-color;

  // Invert the UI text color when there is a dark background
  body.dark-environs-invert-ui & {
    color: $resize-guide-text-inverted-color;
  }
}

.resize-guide-max-before {
  left: -55px;
  text-align: right;
}

.resize-guide-max-after {
  right: -55px;
  text-align: left;
}

.resize-guide-min-before {
  left: 5px;
  text-align: left;
}

.resize-guide-min-after {
  right: 5px;
  text-align: right;
}
