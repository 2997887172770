@import "../../styles/variables.scss";

$close-button-margin: 2px;
$close-button-size: 34px;
$close-icon-colour: $colour-turquoise-500;
$close-icon-colour-hover: $colour-turquoise-600;

button.close {
  // Position in dialog boxes
  position: absolute;
  right: $close-button-margin;
  top: $close-button-margin;

  // Mirror position in rtl
  [dir="rtl"] & {
    right: auto;
    left: $close-button-margin;
  }

  // Touch-friendly size
  width: $close-button-size;
  height: $close-button-size;

  // Appearance
  appearance: none;
  padding: 0;
  border: 0;
  border-radius: 50%;
  color: $close-icon-colour;
  cursor: pointer;
  user-select: none;
  background-color: transparent;

  // Override Octicon sizing
  // A transform works better than overriding the width/height properties.
  > .octicon {
    transform: scale(0.8);
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.5);
    color: $close-icon-colour-hover;
  }

  &:active {
    background-color: rgba(255, 255, 255, 0.75);
  }

  // !important overrides higher-level specificity on font awesome
  // icons that normally exist on buttons. Apply color to the parent
  // element (.close) and the icon will take on this color.
  svg {
    color: inherit !important;
  }

  &:hover svg {
    color: inherit !important;
  }
}
