@import "../../styles/variables.scss";

.description-prompt,
.description-close {
  border-top: 1px solid $ui-colour;
  text-rendering: optimizeLegibility;
  white-space: normal;
  text-align: center;
  padding: 1em 2em;
  user-select: none;
  color: darken($ui-colour, 20%);
  cursor: pointer;
  overflow: hidden;
  border-bottom-left-radius: $border-radius;
  border-bottom-right-radius: $border-radius;

  &:hover {
    color: darken($ui-colour, 40%);
    background: mix($ui-colour, white, 50%);
  }
}

.description-close {
  pointer-events: none;
}

.description-canvas {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  left: -50px;
  top: -50px;
  right: -50px;
  pointer-events: none;
  border-radius: $border-radius;
  box-shadow: $light-box-shadow;
  background: $info-bubble-background;
}

.description {
  height: 100%;
  font-weight: 300;
  line-height: 1.4;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  p {
    margin: 1em 0;

    &:first-child {
      margin-top: 0;
    }
  }

  footer {
    color: rgb(160, 160, 160);
    font-size: 0.8em;
    margin: 1em 0;
  }

  img {
    width: 100%;
    display: block;
  }
}

.description-content {
  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;
  overflow-x: hidden;
  overflow-y: auto;
  touch-action: pan-y;
}

.description-text {
  padding: 1em;
  font-size: 16px;
}

.description-text h1 {
  font-size: 1.5em;
  font-weight: 300;
  line-height: 1.25;
  margin-top: 0;
}
