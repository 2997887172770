@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

// TODO: Further refactoring / splitting up of these styles
$info-bubble-border-radius: $border-radius;

.info-bubble {
  position: absolute;
  min-width: 275px;
  opacity: 0;
  background: $info-bubble-background;
  border-radius: $info-bubble-border-radius;
  box-shadow: $light-box-shadow;
  transition: transform 100ms, margin-top 150ms, height 150ms, opacity 150ms;
  transform: rotateX(-80deg);
  perspective: 1200px;
  z-index: $z-06-info-bubble;
  pointer-events: none;
  touch-action: none;

  &.visible {
    opacity: 1;
    transform: rotateY(0);
    pointer-events: auto;
  }

  header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    padding: 4px 10px;
    height: $info-bubble-button-size + 10px;
    line-height: $info-bubble-button-size + 2px;
    font-size: 1.2em;
    font-weight: 300;
    color: white;
    background: darken($ui-colour, 20%);
    border-top-left-radius: $info-bubble-border-radius;
    border-top-right-radius: $info-bubble-border-radius;
  }

  .icon {
    pointer-events: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 2px 3px;
    fill: #231f20;
  }

  .svg-inline--fa {
    vertical-align: -0.1em;
    height: 11px;
  }

  .variants {
    text-align: left;
    margin-right: 20px;
    min-height: $info-bubble-button-size;
    white-space: nowrap;
    user-select: none;

    button {
      margin-bottom: 2px;
      margin-right: 3px;
    }

    hr {
      display: block;
      margin: 0;
      padding: 0;
      opacity: 0;
      height: 5px;
    }

    button[disabled] .icon {
      opacity: 0.4;
    }

    .fa-lock {
      position: absolute;
      top: -2px;
      right: -2px;
      color: $colour-copper-600;

      [dir="rtl"] & {
        left: -2px;
        right: auto;
      }
    }

    [dir="rtl"] & {
      text-align: right;
      margin-left: 20px;
      margin-right: 0;
      direction: ltr;
    }
  }

  .non-variant {
    text-align: right;
    white-space: nowrap;
    user-select: none;

    /* Takes up the entire width of info-bubble if it is by itself */
    &:only-child {
      width: 100%;
      text-align: center;
    }

    &.building-height {
      margin-top: -36px;
      height: auto;
      white-space: normal;
      width: 115px;
      text-align: center;
    }
  }

  .variants,
  .non-variant {
    button {
      white-space: nowrap;
      position: relative;
      padding: 0;

      .icon {
        opacity: 0.666;
      }

      &:not([disabled]):active {
        .icon {
          margin-top: 1px;
          opacity: 0.9;
        }
      }
    }
  }

  .variant-selected,
  .variant-selected:hover {
    background: $colour-copper-300 !important;
    opacity: 1;

    .icon {
      // Overrides disabled button opacity
      opacity: 1 !important;
    }
  }

  button {
    width: $info-bubble-button-size;
    height: $info-bubble-button-size;
  }
}

.info-bubble-type-building header {
  margin-right: 140px;
  border-top-right-radius: 0;

  [dir="rtl"] & {
    border-top-left-radius: 0;
    border-top-right-radius: $info-bubble-border-radius;
    margin-left: 140px;
    margin-right: 0;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: -40px;
    width: 0;
    height: 0;
    border-top: 40px solid darken($ui-colour, 20%);
    border-right: 40px solid transparent;

    [dir="rtl"] & {
      left: -37px;
      right: auto;
      border-left: 37px solid transparent;
      border-right: none;
    }
  }
}

.info-bubble-controls {
  display: flex;
  justify-content: space-between;
  padding: 10px;

  .non-variant {
    text-align: center;
    width: auto;
  }
}

.info-bubble-warnings {
  margin-top: 1px;
  padding: 0.5em 1.5em;
  border-top: 1px solid $ui-colour;
  text-rendering: optimizeLegibility;
  white-space: normal;
  color: $warning-colour;
  text-align: center;

  > p {
    width: 100%;
    margin: 0.5em auto; /* Ensure centering of text if not wide enough to fill width of bubble */
    padding: 0;
  }
}

.info-bubble.show-description {
  .variants {
    pointer-events: none;
  }

  .non-variant {
    pointer-events: none;
  }

  .warnings {
    pointer-events: none;
  }

  .description-prompt {
    pointer-events: none;
  }

  .description-close {
    pointer-events: auto;
  }

  .description {
    pointer-events: auto;
  }
}

[dir="rtl"] {
  .info-bubble-remove {
    margin-left: 0;
    margin-right: 1em;
  }
}
